.ui.checkbox input:checked ~ label:after {
  transform: scale(0.7);
  background-color: #2a8b5a !important;
  color: #ffff !important;
  font-size: 13px;
  font-weight: 100;
  transform: scale(0.8);
}

.ui.checkbox label {
  color: #2a8b5a !important;
  padding-left: 25px;
  padding-right: 10px;
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  font-size: 12px !important;
}

.checkbox label:before {
  transform: scale(0.8);
}

.checkbox label:after {
  transform: scale(0.8);
}
